import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';

const AshGuidesBeg: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_intro.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Ash Echoes Beginner Guide</h1>
          <h2>
            A beginner guide for Ash Echoes that will teach you everything you
            need to know about the game!
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Reroll guide" />
        <p>Coming Soon!</p>
        <SectionHeader title="Work in progress" />
        <div className="banner countdown">
          <div className="cta">
            <h4>Our Ash Echoes Database is currently being created!</h4>
            <p>
              We're currently gathering all necessary information for the
              upcoming launch!
            </p>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesBeg;

export const Head: React.FC = () => (
  <Seo
    title="Beginner Guide | Ash Echoes | Prydwen Institute"
    description="A beginner guide for Ash Echoes that will teach you everything you need to know about the game!"
    game="ash"
  />
);
